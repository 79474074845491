import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import RequireAuth from './RequireAuth';
import RC from './RouteContent';

// All layouts/containers
import HorizontalLayout from '../layouts/Horizontal';
import VerticalLayout from '../layouts/Vertical';
import Logout from '../pages/account/Logout';
import ScrollToTop from '../components/ScrollToTop';

const GuestTicketDetail = React.lazy(
  () => import('../pages/Tickets/GuestDetail'),
);
const Login = React.lazy(() => import('../pages/account/Login'));

const Tickets = React.lazy(() => import('../pages/Tickets'));
const TicketDetail = React.lazy(() => import('../pages/Tickets/Detail'));

const Home = () => (
  <RequireAuth>
    <Navigate to="/tickets" />
  </RequireAuth>
);

const Router = (props) => {
  const Layout = VerticalLayout;
  const GuestLayout = HorizontalLayout;

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route
          path="/account/login"
          element={<RC isPublic element={<Login />} />}
        />
        <Route path="/account/logout" element={<Logout />} />
        <Route path="/t" element={<GuestLayout />}>
          {/* Ticket view for guest (non-logged in users) */}
          <Route
            path=":ticketId/:ticketSecret"
            element={<RC element={<GuestTicketDetail />} isPublic />}
          />
        </Route>

        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />} />
          <Route path="/tickets">
            <Route
              path=":ticketId"
              element={<RC element={<TicketDetail />} />}
            />
            <Route index element={<RC element={<Tickets />} />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
